@import "@/styles/variables";

.imageWrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.image,
.cardSlot {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image {
  object-fit: cover;
}
